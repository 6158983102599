// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  appDataUrl: 'https://9bv20nap19.execute-api.ap-southeast-2.amazonaws.com/dev/getData',

  prometheus: {
    prometheusPort: 9090,
    grafanaPort: 3000
  },

  reports: {
    companiesSignedInUrl: "https://wt39ggc163.execute-api.ap-southeast-2.amazonaws.com/dev/getReport",
    companiesSignedUpUrl: "https://9ounohujce.execute-api.ap-southeast-2.amazonaws.com/dev/getReport",
    userDetailsUrl: "https://eq4xciccu1.execute-api.ap-southeast-2.amazonaws.com/dev/getUserDetails"
  },
  mobileApps: {
    tradeup: 'https://expo.io/artifacts/69bb1a30-9685-4b58-948a-8c47804b086c'
  },
  admin: {
    listCompanies: 'https://pjtsm72fma.execute-api.ap-southeast-2.amazonaws.com/dev/listCompanies',
    inviteMember: 'https://pjtsm72fma.execute-api.ap-southeast-2.amazonaws.com/dev/inviteMember',
  }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
