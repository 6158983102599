import { Injectable } from '@angular/core';

import { Auth } from 'aws-amplify';
import { async } from 'q';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  public getUser() {
    return Auth.currentAuthenticatedUser()
  }

  public getUserGroups() {
    return new Promise( async(resolve, reject) => {
      const session = await Auth.currentSession();
      const groups = (<any>session).idToken.payload["cognito:groups"];
      resolve(groups);
    });
  }
}
