// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:ed522b8a-0006-40f6-b3a9-a3a55db2acc5",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_1P1WvFttV",
    "aws_user_pools_web_client_id": "5ic8thmr1va13lpf5500dmiq9p"
};


export default awsmobile;
