import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Auth } from 'aws-amplify';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(Auth.currentAuthenticatedUser())
      .pipe(mergeMap((credentials: any) => {
        const idToken = credentials.signInUserSession.idToken.jwtToken;

        request = request.clone({
          setHeaders: {
            Authorization: idToken
          }
        });

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        }, (error: any) => {

          console.error(error);

        }));
      }));
  }
}


