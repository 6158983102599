import { ViewChild, ElementRef, Input, Output, EventEmitter, Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  private modalRef: NgbModalRef;

  @ViewChild('modal') modalElement: ElementRef;

  @Input()
  set visible(visible: boolean) {
    setTimeout(() => {
      if (visible) {
        this.modalRef = this.modalService.open(this.modalElement);
        this.modalRef.result.then(_ => {}, _ => {
          this.onClose.emit();
        });
      } else {
        this.modalRef && this.modalRef.close();
      }
    });
  }

  @Output() onClose: EventEmitter<void> = new EventEmitter();

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

}
