import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@shared/services/auth.service';

import Amplify, { Auth } from 'aws-amplify';
import aws_exports from '../aws-exports';
Amplify.configure(aws_exports);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public waitingAuth: boolean = true;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.waitingAuth = true;

    this.authService.getUser()
      .then(response => {
        this.waitingAuth = false;
      })
      .catch(error => {
        this.router.navigate([ '/login' ]);
        this.waitingAuth = false;

        console.error('Error getting user');
        console.error(error);
      });
  }
}

