import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModalComponent } from './components/modal/modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule
  ],
  declarations: [
    SpinnerComponent,
    MenuComponent,
    ModalComponent
  ],
  exports: [
    SpinnerComponent,
    MenuComponent,
    ModalComponent
  ],
  providers: []
})
export class SharedModule {}
