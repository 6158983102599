import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './modules/home/home.module#HomeModule' },
  { path: 'login', loadChildren: './modules/login/login.module#LoginModule' },
  { path: 'signup', loadChildren: './modules/signup/signup.module#SignupModule' },
  { path: 'members', loadChildren: './modules/members/members.module#MembersModule' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  }) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
